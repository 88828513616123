import { useEffect } from "react"
import RutesScreen from "./navigations/RutesScreen"

function App() {


  return (
    <RutesScreen />
  )
}

export default App
